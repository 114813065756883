import React from "react";
import "./message.css";
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Level from "./level";

import StarIcon from '@mui/icons-material/Star';
import axios from 'axios'
export default function Message({ setOpenMessage, message1,message2,level1, level2}) {


    const handleClose = ()=>{
        setOpenMessage(false);
    }


    const convertToClick = (e) => {
      const evt = new MouseEvent('click', { bubbles: true })
      evt.stopPropagation = () => {}
      e.target.dispatchEvent(evt)
    }
  return (
    <div className="MessageBackground" >
      <div class="MessageContainer">
        <div className="titleCloseBtn" onMouseUp={convertToClick} >
        
        <IconButton onClick={handleClose}  sx={{marginTop: -4 }}  >
        <CloseIcon  />
        </IconButton>

        </div> 
        <div className="group">
         <div className="body">
         <div className="texte">
         <Typography variant="string"  sx={{ marginTop:0, marginRight:0.5, fontFamily:' "Arial", "Helvetica", sans-serif', lineHeight: 1.5 }}>
         <span class="message">{message1} &nbsp; </span><span className="rectangleRougemsg"><span>{ level1}</span></span><span class="message">{message2.toLowerCase()}  </span>&nbsp;<span className="rectangleGoldmsg"><span>{ level2}</span></span>
        </Typography>
        </div>
         </div>
            </div>   
      </div>
    </div>
  );
}
