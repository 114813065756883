import React from "react";
import "./modal.css";
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Level from "./level";

import StarIcon from '@mui/icons-material/Star';
import axios from 'axios'
export default function Modal({ setOpenModal, gym, map, logo,  showLevel, level,resizePin}) {
    const [reviews, setReviews]=React.useState([])
    const [name, setName] = React.useState("")
    const [avr, setAvr] = React.useState(0)
    const style = ()=>{
      if(showLevel === "rectangle"){

        const s = {
          border: '2px solid #A1A9B4'
       }
        return s
      }else if(showLevel === "rectangleRouge"){

        const s = {
          border: '2px solid #FF003F'
       }
        return s
      }else if(showLevel === "rectangleGold"){

        const s = {
          border: '2px solid #FFBB00'
       }
        return s
      }
    }
    const handleClose = ()=>{
      resizePin()
      map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].clear()
      map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(logo);
      setOpenModal(false);
    }
    const classname =()=>{
   
      if(gym.name.length > 20){
       return "modalContainer2"
      }else{
        return "modalContainer"
      }
    }
    const getReviews= async () => {
      await  axios
          .get(` https://yofitt-2e712.ew.r.appspot.com/api/reviews/${gym.id}`)
          .then((res) => {
            const rev = res.data
           // console.log(rev)
            let somme = 0
            if (rev.length > 0) {
              for (let index = 0; index < rev.length; index++) {
               somme +=  rev[index].stars
                
              }
              setAvr(somme/rev.length)
            }
            setReviews(rev)
          })
    }

    React.useEffect(() => {
     // console.log("click************", gym)
    //  getReviews()
      setName(gym.name)
      classname()
      }, [gym])
    const convertToClick = (e) => {
      const evt = new MouseEvent('click', { bubbles: true })
      evt.stopPropagation = () => {}
      e.target.dispatchEvent(evt)
    }
  return (
    <div className="modalBackground" >
      <div class={classname()}>
        <div className="titleCloseBtn" onMouseUp={convertToClick} >
        
        <IconButton onClick={handleClose}  sx={{marginTop: -4 }}  >
        <CloseIcon  />
        </IconButton>

        </div> 
        <div className="group">
        <div className="avatar">
        <Avatar alt="Remy Sharp" style={style()} src={gym.imageURL} sx={{ width: 60, height: 60 }}/>
         </div>
         <div className="body">
         <div className="title">
         <Typography variant="subtitle1" component="h2"  sx={{ marginTop:0.6, marginRight:0.5, fontFamily:' "Arial", "Helvetica", sans-serif', lineHeight: 1 }}>
         <span class="name">{name.toLowerCase()}  </span>
        </Typography>
        </div>
        <div className="reviews">
         <Typography variant="subtitle1" component="h2"  sx={{ marginTop:-1,marginLeft:2.5, fontFamily:' "Arial", "Helvetica", sans-serif'}}>
         <span class="reviewsavr"> {avr}</span>  <span class="fa fa-star checked"></span> <span class="reviewsLength">({reviews.length})</span>
        </Typography>
        </div>
        <div className="level">
          <Level showLevel={showLevel} level={level}/>
        </div>
         </div>
    
            </div>   
      </div>
    </div>
  );
}

