import './Navbar.css'

import React from 'react'



const Navbar = ({cat, filter, tout}) => {

  const makeNames =(chaine)=>{
if (chaine.length > 8) {
  if (chaine.indexOf(" ") != -1) {

    return chaine
  }else{
    return chaine.substring(0, 8) + " " + chaine.substring(8, chaine.length);
  }

}else{
  return chaine
}
  } 
  const classname =(chaine)=>{
    if (chaine.length > 8) {
      return "nameCat"
    
    }else{
      return "nameCat2"
    }
      } 
  return (
    
    <nav className='navbar'>

      <div className='navbar-links'>
        <div className='links'>
        <button onClick={tout} style={{backgroundColor:"#EFF4F2", color:"black"}} ><p class="nameCat2">tout</p></button>
        </div>
  
        {
            cat && cat.map((c)=>(
                <div className='links'>

                <button onClick={(event)=> filter(event, c._id)} id={c._id}  key={c._id} style={{backgroundColor:c.coverColor, color:"white"}}>{c.coverImage && <img className='image' src={c.coverImage} />} <p class={classname(c.name)}>{makeNames(c.name)}</p></button>
        
                </div>
            ))
        }


      </div>

    </nav>
  )
}

export default Navbar