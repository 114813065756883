import React from "react";
import "./modal.css";

import Typography from "@mui/material/Typography";

export default function Level({showLevel, level}) {

  return (
    <Typography variant="subtitle1" component="h2"  sx={{ marginTop:-0.5,marginLeft:2, fontFamily:' "Arial", "Helvetica", sans-serif'}}>
   {level && <div className={ showLevel}><span>YO { level}</span></div>} 
   </Typography>
  );
}
